// components

import React from "react";

import { useMediaQuery } from "react-responsive";

import member1 from "./../../assets/img/member-1.png";
import member2 from "./../../assets/img/member-2.png";
import member3 from "./../../assets/img/member-3.png";
import member5 from "./../../assets/img/member-5.png";
import member8 from "./../../assets/img/member-8.png";
import member9 from "./../../assets/img/member-9.png";

const Desktop = ({ children }: any) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};
const Tablet = ({ children }: any) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? children : null;
};
const Mobile = ({ children }: any) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

class Team extends React.Component<any, any> {
  // @ts-ignore
  public render() {
    return (
      <>
        <Desktop>
          <div className="section-team-container">
            <div className="leadership-team-container">
              <div className="title-container">
                <h2 className="first-title">Leadership Team</h2>
                <span className="second-title">
                  Skilled and Enthusiastic management
                </span>
                <div className="description-container">
                  <p className="description">
                    The Luin leadership team consists of experienced
                    entrepreneurs, knowledgeable investors and engineers who aim
                    to lead the technology.
                  </p>
                </div>
              </div>
              <div className="team-member">
                <div className="team-member-card">
                  <div className="team-member-content">
                    <div className="team-member-img-container">
                      <img src={member1} alt="member1"></img>
                    </div>
                    <div className="team-member-details-container">
                      <h3>İSMAİL BAŞOĞLU</h3>
                      <span>Co-Founder</span>
                      <p>
                        İsmail is a full stack developer and IoT project
                        inventor. He has 10 years experience of programming and
                        2 years experience of blockchain programming. He has
                        been in blockchain area as an investor & developer since
                        2018.
                      </p>
                      <div className="linkedn-container">
                        <a
                          href="https://www.linkedin.com/in/ismailbasoglu/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="team-member-card">
                  <div className="team-member-content">
                    <div className="team-member-img-container">
                      <img src={member2} alt="member2"></img>
                    </div>
                    <div className="team-member-details-container">
                      <h3>MUHAMMET ALİ ACUR</h3>
                      <span>Co-Founder</span>
                      <p>
                        Ali is an entrepreneur who had successfully run IoT
                        startups and involved in tech industry for more than 10
                        years. He is expert at academic research, patent
                        acquisition and testing & development. He won multiple
                        awards from many international competitions and he has
                        been in blockchain area as an investor since 2019.
                      </p>
                      <div className="linkedn-container">
                        <a
                          href="https://www.linkedin.com/in/aliacr/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="team-member-card">
                  <div className="team-member-content">
                    <div className="team-member-img-container">
                      <img src={member3} alt="member2"></img>
                    </div>
                    <div className="team-member-details-container">
                      <h3>HASAN BAŞOĞLU</h3>
                      <span>Co-Founder</span>
                      <p>
                        Hasan is a full stack developer and IoT project
                        inventor. He has 9 years experience of programming and 2
                        years experience of blockchain/smart contract. He has
                        been in blockchain area as an investor & developer since
                        2018.
                      </p>
                      <div className="linkedn-container">
                        <a
                          href="https://www.linkedin.com/in/hasan-ba%C5%9Fo%C4%9Flu/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="core-team-container">
              <div className="title-container">
                <h2 className="first-title">Core Team</h2>
                <span className="second-title">Meet Our Team</span>
                <div className="description-container">
                  <p className="description">
                    We want our team to have young and eager to learn. <br />
                    Forms the core team foundation of a great community.
                  </p>
                </div>
              </div>
              <div className="team-member">
                <div className="team-member-card">
                  <div className="team-member-content">
                    <div className="team-member-img-container">
                      <img src={member5} alt="member2"></img>
                    </div>
                    <div className="team-member-details-container">
                      <h3>ATAMAN ATILKAN</h3>
                      <span>Business Developer</span>
                      <p>
                        Ataman is a business development & strategy manager at
                        Luin. He has been involved in TMT industry since 2013.
                        He is expert at fundraising, investor relations,
                        business development and M&A. He managed multi million
                        dollar projects in his corporate life. He has been in
                        blockchain area as an investor since 2020.​
                      </p>
                      <div className="linkedn-container">
                        <a
                          href="https://www.linkedin.com/in/ataman/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="team-member-card">
                  <div className="team-member-content">
                    <div className="team-member-img-container">
                      <img src={member9} alt="member2"></img>
                    </div>
                    <div className="team-member-details-container">
                      <h3>ÜMİT TEKİN</h3>
                      <span>Token Economy Expert</span>
                      <p>
                      Ümit has been investing in the cryptocurrency market since 2016. He has 7 years of experience in the field of renewable economy.
                      </p>
                      <div className="linkedn-container">
                        <a
                          href="https://www.linkedin.com/in/%C3%BCmit-tekin-589664b8/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="team-member-card">
                  <div className="team-member-content">
                    <div className="team-member-img-container">
                      <img src={member8} alt="member2"></img>
                    </div>
                    <div className="team-member-details-container">
                      <h3>KAAN ÇİMEN</h3>
                      <span>Front-End Developer</span>
                      <p>
                        Kaan is a Front-End developer at Luin. He is dedicated,
                        hardworking and fast learner. He has 1 years experience
                        of development. He has been in blockchain area as an
                        investor since 2021.
                      </p>
                      <div className="linkedn-container">
                        <a
                          href="https://www.linkedin.com/in/kaan-%C3%A7imen-2a1770230/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Desktop>
        <Tablet>
          <div className="section-team-container-tb">
            <div className="leadership-team-container-tb">
              <div className="title-container-tb">
                <h2 className="first-title-tb">Leadership Team</h2>
                <p className="second-title-tb">
                  Skilled and Enthusiastic management
                </p>
                <div className="description-container-tb">
                  <p className="description-tb">
                    The Luin leadership team consists of experienced
                    entrepreneurs, knowledgeable investors and engineers who aim
                    to lead the technology.
                  </p>
                </div>
              </div>
              <div className="team-member-tb">
                <div className="team-member-card-tb">
                  <div className="team-member-content-tb">
                    <div className="team-member-img-container-tb">
                      <img src={member1} alt="member2"></img>
                    </div>
                    <div className="team-member-details-container-tb">
                      <h3>İSMAİL BAŞOĞLU</h3>
                      <span>Co-Founder</span>
                      <p>
                        İsmail is a full stack developer and IoT project
                        inventor. He has 10 years experience of programming and
                        2 years experience of blockchain programming. He has
                        been in blockchain area as an investor & developer since
                        2018.
                      </p>
                      <div className="linkedn-container-tb">
                        <a
                          href="https://www.linkedin.com/in/ismailbasoglu/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="team-member-card-tb">
                  <div className="team-member-content-tb">
                    <div className="team-member-img-container-tb">
                      <img src={member2} alt="member2"></img>
                    </div>
                    <div className="team-member-details-container-tb">
                      <h3>MUHAMMET ALİ ACUR</h3>
                      <span>Co-Founder</span>
                      <p>
                        Ali is an entrepreneur who had successfully run IoT
                        startups and involved in tech industry for more than 10
                        years. He is expert at academic research, patent
                        acquisition and testing & development. He won multiple
                        awards from many international competitions and he has
                        been in blockchain area as an investor since 2019.
                      </p>
                      <div className="linkedn-container-tb">
                        <a
                          href="https://www.linkedin.com/in/aliacr/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="team-member-card-tb">
                  <div className="team-member-content-tb">
                    <div className="team-member-img-container-tb">
                      <img src={member3} alt="member2"></img>
                    </div>
                    <div className="team-member-details-container-tb">
                      <h3>HASAN BAŞOĞLU</h3>
                      <span>Co-Founder</span>
                      <p>
                        Hasan is a full stack developer and IoT project
                        inventor. He has 9 years experience of programming and 2
                        years experience of blockchain/smart contract. He has
                        been in blockchain area as an investor & developer since
                        2018.
                      </p>
                      <div className="linkedn-container-tb">
                        <a
                          href="https://www.linkedin.com/in/hasan-ba%C5%9Fo%C4%9Flu/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="core-team-container-tb">
              <div className="title-container-tb">
                <h2 className="first-title-tb">Core Team</h2>
                <p className="second-title-tb">Meet Our Team</p>
                <div className="description-container-tb">
                  <p className="description-tb">
                    We want our team to have young and eager to learn. <br />
                    Forms the core team foundation of a great community.
                  </p>
                </div>
              </div>
              <div className="team-member-tb">
                <div className="team-member-card-tb">
                  <div className="team-member-content-tb">
                    <div className="team-member-img-container-tb">
                      <img src={member5} alt="member2"></img>
                    </div>
                    <div className="team-member-details-container-tb">
                      <h3>ATAMAN ATILKAN</h3>
                      <span>Business Developer</span>
                      <p>
                        Ataman is a business development & strategy manager at
                        Luin. He has been involved in TMT industry since 2013.
                        He is expert at fundraising, investor relations,
                        business development and M&A. He managed multi million
                        dollar projects in his corporate life. He has been in
                        blockchain area as an investor since 2020.​
                      </p>
                      <div className="linkedn-container-tb">
                        <a
                          href="https://www.linkedin.com/in/ataman/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="team-member-card-tb">
                  <div className="team-member-content-tb">
                    <div className="team-member-img-container-tb">
                      <img src={member9} alt="member2"></img>
                    </div>
                    <div className="team-member-details-container-tb">
                      <h3>ÜMİT TEKİN</h3>
                      <span>Token Economy Expert</span>
                      <p>
                      Ümit has been investing in the cryptocurrency market since 2016. He has 7 years of experience in the field of renewable economy.
                      </p>
                      <div className="linkedn-container">
                        <a
                          href="https://www.linkedin.com/in/%C3%BCmit-tekin-589664b8/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="team-member-card-tb">
                  <div className="team-member-content-tb">
                    <div className="team-member-img-container-tb">
                      <img src={member8} alt="member8"></img>
                    </div>
                    <div className="team-member-details-container-tb">
                      <h3>KAAN ÇİMEN</h3>
                      <span>Front-End Developer</span>
                      <p>
                        Kaan is a Front-End developer at Luin. He is dedicated,
                        hardworking and fast learner. He has 1 years experience
                        of development. He has been in blockchain area as an
                        investor since 2021.
                      </p>
                      <div className="linkedn-container-tb">
                        <a
                          href="https://www.linkedin.com/in/kaan-%C3%A7imen-2a1770230/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Tablet>
        <Mobile>
          <div className="section-team-container-mb">
            <div className="leadership-team-container-mb">
              <div className="title-container-mb">
                <h2 className="first-title-mb">Leadership Team</h2>
                <p className="second-title-mb">
                  Skilled and Enthusiastic management
                </p>
                <div className="description-container-mb">
                  <p className="description-mb">
                    The Luin leadership team consists of experienced
                    entrepreneurs, knowledgeable investors and engineers who aim
                    to lead the technology.
                  </p>
                </div>
              </div>
              <div className="team-member-mb">
                <div className="team-member-card-mb">
                  <div className="team-member-content-mb">
                    <div className="team-member-img-container-mb">
                      <img src={member1} alt="member2"></img>
                    </div>
                    <div className="team-member-details-container-mb">
                      <h3>İSMAİL BAŞOĞLU</h3>
                      <span>Co-Founder</span>
                      <p>
                        İsmail is a full stack developer and IoT project
                        inventor. He has 10 years experience of programming and
                        2 years experience of blockchain programming. He has
                        been in blockchain area as an investor & developer since
                        2018.
                      </p>
                      <div className="linkedn-container-mb">
                        <a
                          href="https://www.linkedin.com/in/ismailbasoglu/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="team-member-card-mb">
                  <div className="team-member-content-mb">
                    <div className="team-member-img-container-mb">
                      <img src={member2} alt="member2"></img>
                    </div>
                    <div className="team-member-details-container-mb">
                      <h3>MUHAMMET ALİ ACUR</h3>
                      <span>Co-Founder</span>
                      <p>
                        Ali is an entrepreneur who had successfully run IoT
                        startups and involved in tech industry for more than 10
                        years. He is expert at academic research, patent
                        acquisition and testing & development. He won multiple
                        awards from many international competitions and he has
                        been in blockchain area as an investor since 2019.
                      </p>
                      <div className="linkedn-container-mb">
                        <a
                          href="https://www.linkedin.com/in/aliacr/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="team-member-card-mb">
                  <div className="team-member-content-mb">
                    <div className="team-member-img-container-mb">
                      <img src={member3} alt="member2"></img>
                    </div>
                    <div className="team-member-details-container-mb">
                      <h3>HASAN BAŞOĞLU</h3>
                      <span>Co-Founder</span>
                      <p>
                        Hasan is a full stack developer and IoT project
                        inventor. He has 9 years experience of programming and 2
                        years experience of blockchain/smart contract. He has
                        been in blockchain area as an investor & developer since
                        2018.
                      </p>
                      <div className="linkedn-container-mb">
                        <a
                          href="https://www.linkedin.com/in/hasan-ba%C5%9Fo%C4%9Flu/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="core-team-container-mb">
              <div className="title-container-mb">
                <h2 className="first-title-mb">Core Team</h2>
                <p className="second-title-mb">Meet Our Team</p>
                <div className="description-container-mb">
                  <p className="description-mb">
                    We want our team to have young and eager to learn. <br />
                    Forms the core team foundation of a great community.
                  </p>
                </div>
              </div>
              <div className="team-member-mb">
                <div className="team-member-card-mb">
                  <div className="team-member-content-mb">
                    <div className="team-member-img-container-mb">
                      <img src={member5} alt="member2"></img>
                    </div>
                    <div className="team-member-details-container-mb">
                      <h3>ATAMAN ATILKAN</h3>
                      <span>Business Developer</span>
                      <p>
                        Ataman is a business development & strategy manager at
                        Luin. He has been involved in TMT industry since 2013.
                        He is expert at fundraising, investor relations,
                        business development and M&A. He managed multi million
                        dollar projects in his corporate life. He has been in
                        blockchain area as an investor since 2020.​
                      </p>
                      <div className="linkedn-container-mb">
                        <a
                          href="https://www.linkedin.com/in/ataman/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="team-member-card-mb">
                  <div className="team-member-content-mb">
                    <div className="team-member-img-container-mb">
                      <img src={member9} alt="member2"></img>
                    </div>
                    <div className="team-member-details-container-mb">
                      <h3>ÜMİT TEKİN</h3>
                      <span>Token Economy Expert</span>
                      <p>
                      Ümit has been investing in the cryptocurrency market since 2016. He has 7 years of experience in the field of renewable economy.
                      </p>
                      <div className="linkedn-container">
                        <a
                          href="https://www.linkedin.com/in/%C3%BCmit-tekin-589664b8/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="team-member-card-mb">
                  <div className="team-member-content-mb">
                    <div className="team-member-img-container-mb">
                      <img src={member8} alt="member2"></img>
                    </div>
                    <div className="team-member-details-container-mb">
                      <h3>KAAN ÇİMEN</h3>
                      <span>Front-End Developer</span>
                      <p>
                        Kaan is a Front-End developer at Luin. He is dedicated,
                        hardworking and fast learner. He has 1 years experience
                        of development. He has been in blockchain area as an
                        investor since 2021.
                      </p>
                      <div className="linkedn-container-mb">
                        <a
                          href="https://www.linkedin.com/in/kaan-%C3%A7imen-2a1770230/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Mobile>
      </>
    );
  }
}

export default Team;
