// components

import React from "react";

import { useMediaQuery } from "react-responsive";

import assetAvax from "./../../assets/img/logos/avalanche.png";
import assetBoldPoint from "./../../assets/img/logos/bold-point.png";
import assetKalao from "./../../assets/img/logos/kalao.png";
import assetCasper from "./../../assets/img/logos/casper.png";
import assetAvalaunch from "./../../assets/img/logos/avalaunch.png";
import assetHubble from "./../../assets/img/logos/hubble.png";
import assetNiftify from "./../../assets/img/logos/niftify.png";
import synapseNetwork from "./../../assets/img/logos/synapse-network.png";
import scottyBeam from "./../../assets/img/logos/scotty.png";
import yayGames from "./../../assets/img/logos/yay-games.png";
import imperiumEmpires from "./../../assets/img/logos/imperium-empires.png";
import assetColony from "./../../assets/img/logos/colony.png";
import platypusFinance from "./../../assets/img/logos/platypus.png";
import assetSolrazr from "./../../assets/img/logos/solrazr.png";
import assetAnimalia from "./../../assets/img/logos/animalia.png";
import assetSolchicks from "./../../assets/img/logos/solchicks.png";
import kaizenFinance from "./../../assets/img/logos/kaizen-finance.png";
import assetHololoot from "./../../assets/img/logos/hololoot.png";
import immutableX from "./../../assets/img/logos/immutable-x.png";
import assetPlaypad from "./../../assets/img/logos/playpad.png";
import assetKitsumon from "./../../assets/img/logos/kitsumon.png";
import battleSaga from "./../../assets/img/logos/battle-saga.png";
import beamSwap from "./../../assets/img/logos/beam-swap.png";
import oneringFinance from "./../../assets/img/logos/onering-finance.png";

const Desktop = ({ children }: any) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};
const Tablet = ({ children }: any) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? children : null;
};
const Mobile = ({ children }: any) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

class Investments extends React.Component<any, any> {
  // @ts-ignore
  public render() {
    return (
      <>
        <Desktop>
          <div className="section-investment-container">
            <div className="title-container">
              <h2 className="first-title">Investments</h2>
              <span className="second-title">
                Some Early Investments We Made
              </span>
              <div className="description-container">
                <p className="description">
                  We invest in projects with the potential to create added value
                  and economy on blockchain. We are equally distant to every
                  blockchain platform. We are assets on many Layer 1 projects.
                  We love to stake.
                </p>
              </div>
            </div>
            <div className="investment-assets-container">
              <div className="investment-assets-content">
                <a href="https://www.avax.network/" target="_blank" rel="noreferrer">
                  <div className="asset-card">
                    <div className="image-container-for-asset-card">
                      <img
                        className="asset-img"
                        src={assetAvax}
                        alt="Avalanche"
                      />
                    </div>
                    <div className="details-container-for-asset-card">
                      <h2 className="asset-name">Avalanche</h2>
                    </div>
                    <div className="type-container">
                      <p>Layer 1</p>
                    </div>
                  </div>
                </a>
                <a href="https://bp.yay.games/" target="_blank" rel="noreferrer">
                  <div className="asset-card">
                    <div className="image-container-for-asset-card">
                      <img
                        className="asset-img"
                        src={assetBoldPoint}
                        alt="Bold Point"
                      />
                    </div>
                    <div className="details-container-for-asset-card">
                      <h2 className="asset-name">Bold Point</h2>
                    </div>
                    <div className="type-container">
                      <p>Gaming</p>
                    </div>
                  </div>
                </a>
                <a href="https://kalao.io/" target="_blank" rel="noreferrer">
                <div className="asset-card">
                    <div className="image-container-for-asset-card">
                      <img className="asset-img" src={assetKalao} alt="Kalao" />
                    </div>
                    <div className="details-container-for-asset-card">
                      <h2 className="asset-name">Kalao</h2>
                    </div>
                    <div className="type-container">
                      <p>NFT</p>
                    </div>
                </div>
                </a>
                <a href="https://casper.network/en/network" target="_blank" rel="noreferrer">
                <div className="asset-card">
                    <div className="image-container-for-asset-card">
                      <img
                        className="asset-img"
                        src={assetCasper}
                        alt="Casper"
                      />
                    </div>
                    <div className="details-container-for-asset-card">
                      <h2 className="asset-name">Casper</h2>
                    </div>
                    <div className="type-container">
                      <p>Layer 1</p>
                    </div>
                </div>
                </a>
                <a href="https://avalaunch.app/" target="_blank" rel="noreferrer">
                <div className="asset-card">
                    <div className="image-container-for-asset-card">
                      <img
                        className="asset-img"
                        src={assetAvalaunch}
                        alt="Avalaunch"
                      />
                    </div>
                    <div className="details-container-for-asset-card">
                      <h2 className="asset-name">Avalaunch</h2>
                    </div>
                    <div className="type-container">
                      <p>Launchpad</p>
                    </div>
                </div>
                </a>
                <a href="https://hubbleprotocol.io/" target="_blank" rel="noreferrer">
                <div className="asset-card">
                    <div className="image-container-for-asset-card">
                      <img
                        className="asset-img-circle"
                        src={assetHubble}
                        alt="Hubble"
                      />
                    </div>
                    <div className="details-container-for-asset-card">
                      <h2 className="asset-name">Hubble</h2>
                    </div>
                    <div className="type-container">
                      <p>DeFi</p>
                    </div>
                </div>
                </a>
                <a href="https://www.niftify.io/" target="_blank" rel="noreferrer">
                <div className="asset-card">
                    <div className="image-container-for-asset-card">
                      <img
                        className="asset-img-circle"
                        src={assetNiftify}
                        alt="Niftify"
                      />
                    </div>
                    <div className="details-container-for-asset-card">
                      <h2 className="asset-name">Niftify</h2>
                    </div>
                    <div className="type-container">
                      <p>NFT</p>
                    </div>
                </div>
                </a>
                <a href="https://synapse.network/" target="_blank" rel="noreferrer">
                <div className="asset-card">
                    <div className="image-container-for-asset-card">
                      <img
                        className="asset-img-circle"
                        src={synapseNetwork}
                        alt="Synapse Network"
                      />
                    </div>
                    <div className="details-container-for-asset-card">
                      <h2 className="asset-name">Synapse Network</h2>
                      <div className="container"></div>
                    </div>
                    <div className="type-container">
                      <p>Launchpad</p>
                    </div>
                </div>
                </a>
                <a href="https://scottybeam.io/" target="_blank" rel="noreferrer">
                <div className="asset-card">
                    <div className="image-container-for-asset-card">
                      <img
                        className="asset-img-circle"
                        src={scottyBeam}
                        alt="Scotty Beam"
                      />
                    </div>
                    <div className="details-container-for-asset-card">
                      <h2 className="asset-name">Scotty Beam</h2>
                    </div>
                    <div className="type-container">
                      <p>NFT</p>
                    </div>
                </div>
                </a>
                <a href="https://www.yay.games/" target="_blank" rel="noreferrer">
                <div className="asset-card">
                    <div className="image-container-for-asset-card">
                      <img
                        className="asset-img-circle"
                        src={yayGames}
                        alt="Yay Games"
                      />
                    </div>
                    <div className="details-container-for-asset-card">
                      <h2 className="asset-name">Yay Games</h2>
                    </div>
                    <div className="type-container">
                      <p>Launchpad</p>
                    </div>
                  
                </div>
                </a>
                <a href="https://imperiumempires.com/" target="_blank" rel="noreferrer">

                <div className="asset-card">
                    <div className="image-container-for-asset-card">
                      <img
                        className="asset-img-circle"
                        src={imperiumEmpires}
                        alt="Imperium Empires"
                      />
                    </div>
                    <div className="details-container-for-asset-card">
                      <h2 className="asset-name">Imperium Empires</h2>
                    </div>
                    <div className="type-container">
                      <p>Gaming</p>
                    </div>
                </div>
                </a>
                <a href="https://www.colonylab.io/" target="_blank" rel="noreferrer">

                <div className="asset-card">
                    <div className="image-container-for-asset-card">
                      <img
                        className="asset-img"
                        src={assetColony}
                        alt="Colony"
                      />
                    </div>
                    <div className="details-container-for-asset-card">
                      <h2 className="asset-name">Colony</h2>
                    </div>
                    <div className="type-container">
                      <p>Funding</p>
                    </div>
                </div>
                </a>
                <a href="https://platypus.finance/" target="_blank" rel="noreferrer">
                <div className="asset-card">
                    <div className="image-container-for-asset-card">
                      <img
                        className="asset-img"
                        src={platypusFinance}
                        alt="Platypus Finance"
                      />
                    </div>
                    <div className="details-container-for-asset-card">
                      <h2 className="asset-name">Platypus Finance</h2>
                    </div>
                    <div className="type-container">
                      <p>DeFi</p>
                    </div>
                </div>
                </a>
                <a href="https://solrazr.com/" target="_blank" rel="noreferrer">
                <div className="asset-card">
                    <div className="image-container-for-asset-card">
                      <img
                        className="asset-img"
                        src={assetSolrazr}
                        alt="Solrazr"
                      />
                    </div>
                    <div className="details-container-for-asset-card">
                      <h2 className="asset-name">Solrazr</h2>
                    </div>
                    <div className="type-container">
                      <p>Launchpad</p>
                    </div>
                </div>
                </a>
                <a href="https://animalia.games/" target="_blank" rel="noreferrer">

                <div className="asset-card">
                    <div className="image-container-for-asset-card">
                      <img
                        className="asset-img"
                        src={assetAnimalia}
                        alt="Animalia"
                      />
                    </div>
                    <div className="details-container-for-asset-card">
                      <h2 className="asset-name">Animalia</h2>
                    </div>
                    <div className="type-container">
                      <p>Gaming</p>
                    </div>
                </div>
                </a>
                <a href="https://www.solchicks.io/" target="_blank" rel="noreferrer">

                <div className="asset-card">
                    <div className="image-container-for-asset-card">
                      <img
                        className="asset-img"
                        src={assetSolchicks}
                        alt="Solchicks"
                      />
                    </div>
                    <div className="details-container-for-asset-card">
                      <h2 className="asset-name">Solchicks</h2>
                    </div>
                    <div className="type-container">
                      <p>Gaming</p>
                    </div>
                </div>
                </a>
                <a href="https://kaizen.finance/" target="_blank" rel="noreferrer">
                <div className="asset-card">
                    <div className="image-container-for-asset-card">
                      <img
                        className="asset-img"
                        src={kaizenFinance}
                        alt="Kaizen Finance"
                      />
                    </div>
                    <div className="details-container-for-asset-card">
                      <h2 className="asset-name">Kaizen Finance</h2>
                    </div>
                    <div className="type-container">
                      <p>Launchpad</p>
                    </div>
                </div>
                </a>
                <a href="https://www.hololoot.io/" target="_blank" rel="noreferrer">
                <div className="asset-card">
                    <div className="image-container-for-asset-card">
                      <img
                        className="asset-img-circle"
                        src={assetHololoot}
                        alt="Hololoot"
                      />
                    </div>
                    <div className="details-container-for-asset-card">
                      <h2 className="asset-name">Hololoot</h2>
                    </div>
                    <div className="type-container">
                      <p>NFT</p>
                    </div>
                </div>
                </a>
                <a href="https://www.immutable.com/" target="_blank" rel="noreferrer">
                <div className="asset-card">
                    <div className="image-container-for-asset-card">
                      <img
                        className="asset-img"
                        src={immutableX}
                        alt="Immutable"
                      />
                    </div>
                    <div className="details-container-for-asset-card">
                      <h2 className="asset-name">Immutable X</h2>
                    </div>
                    <div className="type-container">
                      <p>Layer 2</p>
                    </div>
                </div>
                </a>
                <a href="https://playpad.app/" target="_blank" rel="noreferrer">
                <div className="asset-card">
                    <div className="image-container-for-asset-card">
                      <img
                        className="asset-img"
                        src={assetPlaypad}
                        alt="PlayPad"
                      />
                    </div>
                    <div className="details-container-for-asset-card">
                      <h2 className="asset-name">PlayPad</h2>
                    </div>
                    <div className="type-container">
                      <p>Launchpad</p>
                    </div>
                </div>
                </a>
                <a href="https://kitsumon.com/" target="_blank" rel="noreferrer">
                <div className="asset-card">
                    <div className="image-container-for-asset-card">
                      <img
                        className="asset-img"
                        src={assetKitsumon}
                        alt="Kitsumon"
                      />
                    </div>
                    <div className="details-container-for-asset-card">
                      <h2 className="asset-name">Kitsumon</h2>
                    </div>
                    <div className="type-container">
                      <p>Gaming</p>
                    </div>
                </div>
                </a>

                <a href="https://www.battlesaga.io/" target="_blank" rel="noreferrer">
                <div className="asset-card">
                    <div className="image-container-for-asset-card">
                      <img
                        className="asset-img"
                        src={battleSaga}
                        alt="BattleSaga"
                      />
                    </div>
                    <div className="details-container-for-asset-card">
                      <h2 className="asset-name">Battle Saga</h2>
                    </div>
                    <div className="type-container">
                      <p>Gaming</p>
                    </div>
                </div>
                </a>

                <a href="https://beamswap.io/" target="_blank" rel="noreferrer">
                <div className="asset-card">
                    <div className="image-container-for-asset-card">
                      <img
                        className="asset-img"
                        src={beamSwap}
                        alt="BeamSwap"
                      />
                    </div>
                    <div className="details-container-for-asset-card">
                      <h2 className="asset-name">Beam Swap</h2>
                    </div>
                    <div className="type-container">
                      <p>DeFi</p>
                    </div>
                </div>
                </a>
                <a href="https://www.onering.finance/" target="_blank" rel="noreferrer">
                  <div className="asset-card">
                    <div className="image-container-for-asset-card">
                      <img
                        className="asset-img"
                        src={oneringFinance}
                        alt="Onering Finance"
                      />
                    </div>
                    <div className="details-container-for-asset-card">
                      <h2 className="asset-name">Onering Finance</h2>
                    </div>
                    <div className="type-container">
                      <p>DeFi</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </Desktop>
        <Tablet>
        <div className="section-investment-container-tb">
            <div className="title-container-tb">
              <h2 className="first-title-tb">Investments</h2>
              <p className="second-title-tb">
                Some Early Investments We Made
              </p>
              <div className="description-container-tb">
                <p className="description-tb">
                  We invest in projects with the potential to create added value
                  and economy on blockchain. We are equally distant to every
                  blockchain platform. We are assets on many Layer 1 projects.
                  We love to stake.
                </p>
              </div>
            </div>
            <div className="investment-assets-container-tb">
              <div className="investment-assets-content-tb">
                <a href="https://www.avax.network/" target="_blank" rel="noreferrer">
                  <div className="asset-card-tb">
                    <div className="image-container-for-asset-card-tb">
                      <img
                        className="asset-img-tb"
                        src={assetAvax}
                        alt="Avalanche"
                      />
                    </div>
                    <div className="details-container-for-asset-card-tb">
                      <h2 className="asset-name-tb">Avalanche</h2>
                    </div>
                    <div className="type-container-tb">
                      <p>Layer 1</p>
                    </div>
                  </div>
                </a>
                <a href="https://bp.yay.games/" target="_blank" rel="noreferrer">
                  <div className="asset-card-tb">
                    <div className="image-container-for-asset-card-tb">
                      <img
                        className="asset-img-tb"
                        src={assetBoldPoint}
                        alt="Bold Point"
                      />
                    </div>
                    <div className="details-container-for-asset-card-tb">
                      <h2 className="asset-name-tb">Bold Point</h2>
                    </div>
                    <div className="type-container-tb">
                      <p>Gaming</p>
                    </div>
                  </div>
                </a>
                <a href="https://kalao.io/" target="_blank" rel="noreferrer">
                <div className="asset-card-tb">
                    <div className="image-container-for-asset-card-tb">
                      <img className="asset-img-tb" src={assetKalao} alt="Kalao" />
                    </div>
                    <div className="details-container-for-asset-card-tb">
                      <h2 className="asset-name-tb">Kalao</h2>
                    </div>
                    <div className="type-container-tb">
                      <p>NFT</p>
                    </div>
                </div>
                </a>
                <a href="https://casper.network/en/network" target="_blank" rel="noreferrer">
                <div className="asset-card-tb">
                    <div className="image-container-for-asset-card-tb">
                      <img
                        className="asset-img-tb"
                        src={assetCasper}
                        alt="Casper"
                      />
                    </div>
                    <div className="details-container-for-asset-card-tb">
                      <h2 className="asset-name-tb">Casper</h2>
                    </div>
                    <div className="type-container-tb">
                      <p>Layer 1</p>
                    </div>
                </div>
                </a>
                <a href="https://avalaunch.app/" target="_blank" rel="noreferrer">
                <div className="asset-card-tb">
                    <div className="image-container-for-asset-card-tb">
                      <img
                        className="asset-img-tb"
                        src={assetAvalaunch}
                        alt="Avalaunch"
                      />
                    </div>
                    <div className="details-container-for-asset-card-tb">
                      <h2 className="asset-name-tb">Avalaunch</h2>
                    </div>
                    <div className="type-container-tb">
                      <p>Launchpad</p>
                    </div>
                </div>
                </a>
                <a href="https://hubbleprotocol.io/" target="_blank" rel="noreferrer">
                <div className="asset-card-tb">
                    <div className="image-container-for-asset-card-tb">
                      <img
                        className="asset-img-circle-tb"
                        src={assetHubble}
                        alt="Hubble"
                      />
                    </div>
                    <div className="details-container-for-asset-card-tb">
                      <h2 className="asset-name-tb">Hubble</h2>
                    </div>
                    <div className="type-container-tb">
                      <p>DeFi</p>
                    </div>
                </div>
                </a>
                <a href="https://www.niftify.io/" target="_blank" rel="noreferrer">
                <div className="asset-card-tb">
                    <div className="image-container-for-asset-card-tb">
                      <img
                        className="asset-img-circle-tb"
                        src={assetNiftify}
                        alt="Niftify"
                      />
                    </div>
                    <div className="details-container-for-asset-card-tb">
                      <h2 className="asset-name-tb">Niftify</h2>
                    </div>
                    <div className="type-container-tb">
                      <p>NFT</p>
                    </div>
                </div>
                </a>
                <a href="https://synapse.network/" target="_blank" rel="noreferrer">
                <div className="asset-card-tb">
                    <div className="image-container-for-asset-card-tb">
                      <img
                        className="asset-img-circle-tb"
                        src={synapseNetwork}
                        alt="Synapse Network"
                      />
                    </div>
                    <div className="details-container-for-asset-card-tb">
                      <h2 className="asset-name-tb">Synapse Network</h2>
                      <div className="container-tb"></div>
                    </div>
                    <div className="type-container-tb">
                      <p>Launchpad</p>
                    </div>
                </div>
                </a>
                <a href="https://scottybeam.io/" target="_blank" rel="noreferrer">
                <div className="asset-card-tb">
                    <div className="image-container-for-asset-card-tb">
                      <img
                        className="asset-img-circle-tb"
                        src={scottyBeam}
                        alt="Scotty Beam"
                      />
                    </div>
                    <div className="details-container-for-asset-card-tb">
                      <h2 className="asset-name-tb">Scotty Beam</h2>
                    </div>
                    <div className="type-container-tb">
                      <p>NFT</p>
                    </div>
                </div>
                </a>
                <a href="https://www.yay.games/" target="_blank" rel="noreferrer">
                <div className="asset-card-tb">
                    <div className="image-container-for-asset-card-tb">
                      <img
                        className="asset-img-circle-tb"
                        src={yayGames}
                        alt="Yay Games"
                      />
                    </div>
                    <div className="details-container-for-asset-card-tb">
                      <h2 className="asset-name-tb">Yay Games</h2>
                    </div>
                    <div className="type-container-tb">
                      <p>Launchpad</p>
                    </div>
                  
                </div>
                </a>
                <a href="https://imperiumempires.com/" target="_blank" rel="noreferrer">

                <div className="asset-card-tb">
                    <div className="image-container-for-asset-card-tb">
                      <img
                        className="asset-img-circle-tb"
                        src={imperiumEmpires}
                        alt="Imperium Empires"
                      />
                    </div>
                    <div className="details-container-for-asset-card-tb">
                      <h2 className="asset-name-tb">Imperium Empires</h2>
                    </div>
                    <div className="type-container-tb">
                      <p>Gaming</p>
                    </div>
                </div>
                </a>
                <a href="https://www.colonylab.io/" target="_blank" rel="noreferrer">

                <div className="asset-card-tb">
                    <div className="image-container-for-asset-card-tb">
                      <img
                        className="asset-img-tb"
                        src={assetColony}
                        alt="Colony"
                      />
                    </div>
                    <div className="details-container-for-asset-card-tb">
                      <h2 className="asset-name-tb">Colony</h2>
                    </div>
                    <div className="type-container-tb">
                      <p>Funding</p>
                    </div>
                </div>
                </a>
                <a href="https://platypus.finance/" target="_blank" rel="noreferrer">
                <div className="asset-card-tb">
                    <div className="image-container-for-asset-card-tb">
                      <img
                        className="asset-img-tb"
                        src={platypusFinance}
                        alt="Platypus Finance"
                      />
                    </div>
                    <div className="details-container-for-asset-card-tb">
                      <h2 className="asset-name-tb">Platypus Finance</h2>
                    </div>
                    <div className="type-container-tb">
                      <p>DeFi</p>
                    </div>
                </div>
                </a>
                <a href="https://solrazr.com/" target="_blank" rel="noreferrer">
                <div className="asset-card-tb">
                    <div className="image-container-for-asset-card-tb">
                      <img
                        className="asset-img-tb"
                        src={assetSolrazr}
                        alt="Solrazr"
                      />
                    </div>
                    <div className="details-container-for-asset-card-tb">
                      <h2 className="asset-name-tb">Solrazr</h2>
                    </div>
                    <div className="type-container-tb">
                      <p>Launchpad</p>
                    </div>
                </div>
                </a>
                <a href="https://animalia.games/" target="_blank" rel="noreferrer">

                <div className="asset-card-tb">
                    <div className="image-container-for-asset-card-tb">
                      <img
                        className="asset-img-tb"
                        src={assetAnimalia}
                        alt="Animalia"
                      />
                    </div>
                    <div className="details-container-for-asset-card-tb">
                      <h2 className="asset-name-tb">Animalia</h2>
                    </div>
                    <div className="type-container-tb">
                      <p>Gaming</p>
                    </div>
                </div>
                </a>
                <a href="https://www.solchicks.io/" target="_blank" rel="noreferrer">

                <div className="asset-card-tb">
                    <div className="image-container-for-asset-card-tb">
                      <img
                        className="asset-img-tb"
                        src={assetSolchicks}
                        alt="Solchicks"
                      />
                    </div>
                    <div className="details-container-for-asset-card-tb">
                      <h2 className="asset-name-tb">Solchicks</h2>
                    </div>
                    <div className="type-container-tb">
                      <p>Gaming</p>
                    </div>
                </div>
                </a>
                <a href="https://kaizen.finance/" target="_blank" rel="noreferrer">
                <div className="asset-card-tb">
                    <div className="image-container-for-asset-card-tb">
                      <img
                        className="asset-img-tb"
                        src={kaizenFinance}
                        alt="Kaizen Finance"
                      />
                    </div>
                    <div className="details-container-for-asset-card-tb">
                      <h2 className="asset-name-tb">Kaizen Finance</h2>
                    </div>
                    <div className="type-container-tb">
                      <p>Launchpad</p>
                    </div>
                </div>
                </a>
                <a href="https://www.hololoot.io/" target="_blank" rel="noreferrer">
                <div className="asset-card-tb">
                    <div className="image-container-for-asset-card-tb">
                      <img
                        className="asset-img-circle-tb"
                        src={assetHololoot}
                        alt="Hololoot"
                      />
                    </div>
                    <div className="details-container-for-asset-card-tb">
                      <h2 className="asset-name-tb">Hololoot</h2>
                    </div>
                    <div className="type-container-tb">
                      <p>NFT</p>
                    </div>
                </div>
                </a>
                <a href="https://www.immutable.com/" target="_blank" rel="noreferrer">
                <div className="asset-card-tb">
                    <div className="image-container-for-asset-card-tb">
                      <img
                        className="asset-img-tb"
                        src={immutableX}
                        alt="Immutable"
                      />
                    </div>
                    <div className="details-container-for-asset-card-tb">
                      <h2 className="asset-name-tb">Immutable X</h2>
                    </div>
                    <div className="type-container-tb">
                      <p>Layer 2</p>
                    </div>
                </div>
                </a>
                <a href="https://playpad.app/" target="_blank" rel="noreferrer">
                <div className="asset-card-tb">
                    <div className="image-container-for-asset-card-tb">
                      <img
                        className="asset-img-tb"
                        src={assetPlaypad}
                        alt="PlayPad"
                      />
                    </div>
                    <div className="details-container-for-asset-card-tb">
                      <h2 className="asset-name-tb">PlayPad</h2>
                    </div>
                    <div className="type-container-tb">
                      <p>Launchpad</p>
                    </div>
                </div>
                </a>

                <a href="https://kitsumon.com/" target="_blank" rel="noreferrer">

                <div className="asset-card-tb">
                    <div className="image-container-for-asset-card-tb">
                      <img
                        className="asset-img-tb"
                        src={assetKitsumon}
                        alt="Kitsumon"
                      />
                    </div>
                    <div className="details-container-for-asset-card-tb">
                      <h2 className="asset-name-tb">Kitsumon</h2>
                    </div>
                    <div className="type-container-tb">
                      <p>Gaming</p>
                    </div>
                </div>
                </a>

                <a href="https://www.battlesaga.io/" target="_blank" rel="noreferrer">
                <div className="asset-card-tb">
                    <div className="image-container-for-asset-card-tb">
                      <img
                        className="asset-img-tb"
                        src={battleSaga}
                        alt="BattleSaga"
                      />
                    </div>
                    <div className="details-container-for-asset-card-tb">
                      <h2 className="asset-name-tb">Battle Saga</h2>
                    </div>
                    <div className="type-container-tb">
                      <p>Gaming</p>
                    </div>
                </div>
                </a>

                <a href="https://beamswap.io/" target="_blank" rel="noreferrer">
                <div className="asset-card-tb">
                    <div className="image-container-for-asset-card-tb">
                      <img
                        className="asset-img-tb"
                        src={beamSwap}
                        alt="BeamSwap"
                      />
                    </div>
                    <div className="details-container-for-asset-card-tb">
                      <h2 className="asset-name-tb">Beam Swap</h2>
                    </div>
                    <div className="type-container-tb">
                      <p>DeFi</p>
                    </div>
                </div>
                </a>
                <a href="https://www.onering.finance/" target="_blank" rel="noreferrer">
                  <div className="asset-card-tb">
                    <div className="image-container-for-asset-card-tb">
                      <img
                        className="asset-img-tb"
                        src={oneringFinance}
                        alt="Onering Finance"
                      />
                    </div>
                    <div className="details-container-for-asset-card-tb">
                      <h2 className="asset-name-tb">Onering Finance</h2>
                    </div>
                    <div className="type-container-tb">
                      <p>DeFi</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </Tablet>
        <Mobile>
        <div className="section-investment-container-mb">
            <div className="title-container-mb">
              <h2 className="first-title-mb">Investments</h2>
              <p className="second-title-mb">
                Some Early Investments We Made
              </p>
              <div className="description-container-mb">
                <p className="description-mb">
                  We invest in projects with the potential to create added value
                  and economy on blockchain. We are equally distant to every
                  blockchain platform. We are assets on many Layer 1 projects.
                  We love to stake.
                </p>
              </div>
            </div>
            <div className="investment-assets-container-mb">
              <div className="investment-assets-content-mb">
                <a href="https://www.avax.network/" target="_blank" rel="noreferrer">
                  <div className="asset-card-mb">
                    <div className="image-container-for-asset-card-mb">
                      <img
                        className="asset-img-mb"
                        src={assetAvax}
                        alt="Avalanche"
                      />
                    </div>
                    <div className="details-container-for-asset-card-mb">
                      <h2 className="asset-name-mb">Avalanche</h2>
                    </div>
                    <div className="type-container-mb">
                      <p>Layer 1</p>
                    </div>
                  </div>
                </a>
                <a href="https://bp.yay.games/" target="_blank" rel="noreferrer">
                  <div className="asset-card-mb">
                    <div className="image-container-for-asset-card-mb">
                      <img
                        className="asset-img-mb"
                        src={assetBoldPoint}
                        alt="Bold Point"
                      />
                    </div>
                    <div className="details-container-for-asset-card-mb">
                      <h2 className="asset-name-mb">Bold Point</h2>
                    </div>
                    <div className="type-container-mb">
                      <p>Gaming</p>
                    </div>
                  </div>
                </a>
                <a href="https://kalao.io/" target="_blank" rel="noreferrer">
                <div className="asset-card-mb">
                    <div className="image-container-for-asset-card-mb">
                      <img className="asset-img-mb" src={assetKalao} alt="Kalao" />
                    </div>
                    <div className="details-container-for-asset-card-mb">
                      <h2 className="asset-name-mb">Kalao</h2>
                    </div>
                    <div className="type-container-mb">
                      <p>NFT</p>
                    </div>
                </div>
                </a>
                <a href="https://casper.network/en/network" target="_blank" rel="noreferrer">
                <div className="asset-card-mb">
                    <div className="image-container-for-asset-card-mb">
                      <img
                        className="asset-img-mb"
                        src={assetCasper}
                        alt="Casper"
                      />
                    </div>
                    <div className="details-container-for-asset-card-mb">
                      <h2 className="asset-name-mb">Casper</h2>
                    </div>
                    <div className="type-container-mb">
                      <p>Layer 1</p>
                    </div>
                </div>
                </a>
                <a href="https://avalaunch.app/" target="_blank" rel="noreferrer">
                <div className="asset-card-mb">
                    <div className="image-container-for-asset-card-mb">
                      <img
                        className="asset-img-mb"
                        src={assetAvalaunch}
                        alt="Avalaunch"
                      />
                    </div>
                    <div className="details-container-for-asset-card-mb">
                      <h2 className="asset-name-mb">Avalaunch</h2>
                    </div>
                    <div className="type-container-mb">
                      <p>Launchpad</p>
                    </div>
                </div>
                </a>
                <a href="https://hubbleprotocol.io/" target="_blank" rel="noreferrer">
                <div className="asset-card-mb">
                    <div className="image-container-for-asset-card-mb">
                      <img
                        className="asset-img-circle-mb"
                        src={assetHubble}
                        alt="Hubble"
                      />
                    </div>
                    <div className="details-container-for-asset-card-mb">
                      <h2 className="asset-name-mb">Hubble</h2>
                    </div>
                    <div className="type-container-mb">
                      <p>DeFi</p>
                    </div>
                </div>
                </a>
                <a href="https://www.niftify.io/" target="_blank" rel="noreferrer">
                <div className="asset-card-mb">
                    <div className="image-container-for-asset-card-mb">
                      <img
                        className="asset-img-circle-mb"
                        src={assetNiftify}
                        alt="Niftify"
                      />
                    </div>
                    <div className="details-container-for-asset-card-mb">
                      <h2 className="asset-name-mb">Niftify</h2>
                    </div>
                    <div className="type-container-mb">
                      <p>NFT</p>
                    </div>
                </div>
                </a>
                <a href="https://synapse.network/" target="_blank" rel="noreferrer">
                <div className="asset-card-mb">
                    <div className="image-container-for-asset-card-mb">
                      <img
                        className="asset-img-circle-mb"
                        src={synapseNetwork}
                        alt="Synapse Network"
                      />
                    </div>
                    <div className="details-container-for-asset-card-mb">
                      <h2 className="asset-name-mb">Synapse Network</h2>
                      <div className="container-mb"></div>
                    </div>
                    <div className="type-container-mb">
                      <p>Launchpad</p>
                    </div>
                </div>
                </a>
                <a href="https://scottybeam.io/" target="_blank" rel="noreferrer">
                <div className="asset-card-mb">
                    <div className="image-container-for-asset-card-mb">
                      <img
                        className="asset-img-circle-mb"
                        src={scottyBeam}
                        alt="Scotty Beam"
                      />
                    </div>
                    <div className="details-container-for-asset-card-mb">
                      <h2 className="asset-name-mb">Scotty Beam</h2>
                    </div>
                    <div className="type-container-mb">
                      <p>NFT</p>
                    </div>
                </div>
                </a>
                <a href="https://www.yay.games/" target="_blank" rel="noreferrer">
                <div className="asset-card-mb">
                    <div className="image-container-for-asset-card-mb">
                      <img
                        className="asset-img-circle-mb"
                        src={yayGames}
                        alt="Yay Games"
                      />
                    </div>
                    <div className="details-container-for-asset-card-mb">
                      <h2 className="asset-name-mb">Yay Games</h2>
                    </div>
                    <div className="type-container-mb">
                      <p>Launchpad</p>
                    </div>
                  
                </div>
                </a>
                <a href="https://imperiumempires.com/" target="_blank" rel="noreferrer">

                <div className="asset-card-mb">
                    <div className="image-container-for-asset-card-mb">
                      <img
                        className="asset-img-circle-mb"
                        src={imperiumEmpires}
                        alt="Imperium Empires"
                      />
                    </div>
                    <div className="details-container-for-asset-card-mb">
                      <h2 className="asset-name-mb">Imperium Empires</h2>
                    </div>
                    <div className="type-container-mb">
                      <p>Gaming</p>
                    </div>
                </div>
                </a>
                <a href="https://www.colonylab.io/" target="_blank" rel="noreferrer">

                <div className="asset-card-mb">
                    <div className="image-container-for-asset-card-mb">
                      <img
                        className="asset-img-mb"
                        src={assetColony}
                        alt="Colony"
                      />
                    </div>
                    <div className="details-container-for-asset-card-mb">
                      <h2 className="asset-name-mb">Colony</h2>
                    </div>
                    <div className="type-container-mb">
                      <p>Funding</p>
                    </div>
                </div>
                </a>
                <a href="https://platypus.finance/" target="_blank" rel="noreferrer">
                <div className="asset-card-mb">
                    <div className="image-container-for-asset-card-mb">
                      <img
                        className="asset-img-mb"
                        src={platypusFinance}
                        alt="Platypus Finance"
                      />
                    </div>
                    <div className="details-container-for-asset-card-mb">
                      <h2 className="asset-name-mb">Platypus Finance</h2>
                    </div>
                    <div className="type-container-mb">
                      <p>DeFi</p>
                    </div>
                </div>
                </a>
                <a href="https://solrazr.com/" target="_blank" rel="noreferrer">
                <div className="asset-card-mb">
                    <div className="image-container-for-asset-card-mb">
                      <img
                        className="asset-img-mb"
                        src={assetSolrazr}
                        alt="Solrazr"
                      />
                    </div>
                    <div className="details-container-for-asset-card-mb">
                      <h2 className="asset-name-mb">Solrazr</h2>
                    </div>
                    <div className="type-container-mb">
                      <p>Launchpad</p>
                    </div>
                </div>
                </a>
                <a href="https://animalia.games/" target="_blank" rel="noreferrer">

                <div className="asset-card-mb">
                    <div className="image-container-for-asset-card-mb">
                      <img
                        className="asset-img-mb"
                        src={assetAnimalia}
                        alt="Animalia"
                      />
                    </div>
                    <div className="details-container-for-asset-card-mb">
                      <h2 className="asset-name-mb">Animalia</h2>
                    </div>
                    <div className="type-container-mb">
                      <p>Gaming</p>
                    </div>
                </div>
                </a>
                <a href="https://www.solchicks.io/" target="_blank" rel="noreferrer">

                <div className="asset-card-mb">
                    <div className="image-container-for-asset-card-mb">
                      <img
                        className="asset-img-mb"
                        src={assetSolchicks}
                        alt="Solchicks"
                      />
                    </div>
                    <div className="details-container-for-asset-card-mb">
                      <h2 className="asset-name-mb">Solchicks</h2>
                    </div>
                    <div className="type-container-mb">
                      <p>Gaming</p>
                    </div>
                </div>
                </a>
                <a href="https://kaizen.finance/" target="_blank" rel="noreferrer">
                <div className="asset-card-mb">
                    <div className="image-container-for-asset-card-mb">
                      <img
                        className="asset-img-mb"
                        src={kaizenFinance}
                        alt="Kaizen Finance"
                      />
                    </div>
                    <div className="details-container-for-asset-card-mb">
                      <h2 className="asset-name-mb">Kaizen Finance</h2>
                    </div>
                    <div className="type-container-mb">
                      <p>Launchpad</p>
                    </div>
                </div>
                </a>
                <a href="https://www.hololoot.io/" target="_blank" rel="noreferrer">
                <div className="asset-card-mb">
                    <div className="image-container-for-asset-card-mb">
                      <img
                        className="asset-img-circle-mb"
                        src={assetHololoot}
                        alt="Hololoot"
                      />
                    </div>
                    <div className="details-container-for-asset-card-mb">
                      <h2 className="asset-name-mb">Hololoot</h2>
                    </div>
                    <div className="type-container-mb">
                      <p>NFT</p>
                    </div>
                </div>
                </a>
                <a href="https://www.immutable.com/" target="_blank" rel="noreferrer">
                <div className="asset-card-mb">
                    <div className="image-container-for-asset-card-mb">
                      <img
                        className="asset-img-mb"
                        src={immutableX}
                        alt="Immutable"
                      />
                    </div>
                    <div className="details-container-for-asset-card-mb">
                      <h2 className="asset-name-mb">Immutable X</h2>
                    </div>
                    <div className="type-container-mb">
                      <p>Layer 2</p>
                    </div>
                </div>
                </a>
                <a href="https://playpad.app/" target="_blank" rel="noreferrer">
                <div className="asset-card-mb">
                    <div className="image-container-for-asset-card-mb">
                      <img
                        className="asset-img-mb"
                        src={assetPlaypad}
                        alt="PlayPad"
                      />
                    </div>
                    <div className="details-container-for-asset-card-mb">
                      <h2 className="asset-name-mb">PlayPad</h2>
                    </div>
                    <div className="type-container-mb">
                      <p>Launchpad</p>
                    </div>
                </div>
                </a>
                <a href="https://kitsumon.com/" target="_blank" rel="noreferrer">

                <div className="asset-card-mb">
                    <div className="image-container-for-asset-card-mb">
                      <img
                        className="asset-img-mb"
                        src={assetKitsumon}
                        alt="Kitsumon"
                      />
                    </div>
                    <div className="details-container-for-asset-card-mb">
                      <h2 className="asset-name-mb">Kitsumon</h2>
                    </div>
                    <div className="type-container-mb">
                      <p>Gaming</p>
                    </div>
                </div>
                </a>

                <a href="https://www.battlesaga.io/" target="_blank" rel="noreferrer">
                <div className="asset-card-mb">
                    <div className="image-container-for-asset-card-mb">
                      <img
                        className="asset-img-mb"
                        src={battleSaga}
                        alt="BattleSaga"
                      />
                    </div>
                    <div className="details-container-for-asset-card-mb">
                      <h2 className="asset-name-mb">Battle Saga</h2>
                    </div>
                    <div className="type-container-mb">
                      <p>Gaming</p>
                    </div>
                </div>
                </a>
                <a href="https://beamswap.io/" target="_blank" rel="noreferrer">
                <div className="asset-card-mb">
                    <div className="image-container-for-asset-card-mb">
                      <img
                        className="asset-img-mb"
                        src={beamSwap}
                        alt="BeamSwap"
                      />
                    </div>
                    <div className="details-container-for-asset-card-mb">
                      <h2 className="asset-name-mb">Beam Swap</h2>
                    </div>
                    <div className="type-container-mb">
                      <p>DeFi</p>
                    </div>
                </div>
                </a>
                <a href="https://www.onering.finance/" target="_blank" rel="noreferrer">
                  <div className="asset-card-mb">
                    <div className="image-container-for-asset-card-mb">
                      <img
                        className="asset-img-mb"
                        src={oneringFinance}
                        alt="Onering Finance"
                      />
                    </div>
                    <div className="details-container-for-asset-card-mb">
                      <h2 className="asset-name-mb">Onering Finance</h2>
                    </div>
                    <div className="type-container-mb">
                      <p>DeFi</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </Mobile>
      </>
    );
  }
}

export default Investments;
