// components

import Footer from "./../components/Footers/Footer";
import React from "react";
import About from "./app/About";
import Header from "./../components/Header/Header";
import { ParallaxProvider } from "react-scroll-parallax";
import Team from "./app/Team";
import Investments from "./app/Investments";
import Projects from "./app/Projects";
import Zoom from "./app/Zoom";
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';

class Landing extends React.Component<any, any> {
  ref: HTMLElement | null | undefined;

  // @ts-ignore
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.state = {
      navbarOpen: false,
      setNavbarOpen: false,
      classNameFab: "",
      classNameArrow: "",
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll = () => {
    if (window.pageYOffset > 0) {
      if (!this.state.classNameFab) {
        this.setState({ classNameFab: "show" });
      }
      if (!this.state.classNameArrow) {
        this.setState({ classNameArrow: "hidden" });
      }
    } else {
      if (this.state.classNameFab) {
        this.setState({ classNameFab: "" });
      }
      if (this.state.classNameArrow) {
        this.setState({ classNameArrow: "" });
      }
    }
  };

  // @ts-ignore
  public render() {
    return (
      <>
        <Header fixed />

        <main ref={(r) => (this.ref = r)}>
          <ParallaxProvider>
            <Zoom />

            <section id="about" className="section-about">
              <About />
            </section>

            <section id="projects" className="section-projects">
              <Projects />
            </section>

            <section id="investments" className="section-investments">
              <Investments />
            </section>
            <section id="team" className="section-team">
              <Team />
            </section>
          </ParallaxProvider>
          <div className={`fab-container ${this.state.classNameFab}`}>
            <a className="fab-content" href="#top">
              <i className="fas fa-chevron-up"></i>
            </a>
          </div>
        </main>
        <Footer />
      </>
    );
  }
}

export default Landing;
