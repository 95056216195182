// components

import React from "react";

import { useMediaQuery } from "react-responsive";

import projectRavelin from "./../../assets/img/project-ravelin.png";
import ravelinBanner from "./../../assets/img/ravelin-banner.jpg";
import projectStagera from "./../../assets/img/project-stagera.png";
import stageraBanner from "./../../assets/img/stagera-banner.jpg";
import projectLuinNetwork from "./../../assets/img/project-luin-network.png";
import luinNetworkBanner from "./../../assets/img/luin-network-banner.png";

const Desktop = ({ children }: any) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};
const Tablet = ({ children }: any) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? children : null;
};
const Mobile = ({ children }: any) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

class Projects extends React.Component<any, any> {
  // @ts-ignore
  public render() {
    return (
      <>
        <Desktop>
          <div className="section-project-container">
            <div className="title-container">
              <h2 className="first-title">Projects</h2>
              <span className="second-title">
                We Develop Projects That will Create Added Value
              </span>
              <div className="description-container">
                <p className="description">
                  We work hard and have fun together. As an early believers of
                  web3.0, we would like to change the world by undersigning
                  projects that will create an advanced virtual economy for the
                  benefit of people, powered by blockchain technology.
                </p>
              </div>
            </div>
            <div className="project-assets-container">
              <div className="first project-card">
                <img
                  className="project-card-profile-img"
                  src={ravelinBanner}
                  alt=""
                />
                <div className="project-card-description-bk"></div>
                <div className="project-card-logo">
                  <img className="ravelin-logo-img" src={projectRavelin} alt="" />
                </div>
                <div className="project-card-learn-more">
                <a href="https://ravelin.network" className="btn-learn-more" target="_blank" rel="noreferrer">
                  <i className="fa fa-globe"></i>
                  </a>
                </div>
                <div className="project-card-description-container">
                  <h2 className="project-card-title">Ravelin</h2>
                  <div className="project-card-categories">
                  <span className="project-card-category">Gaming</span>
                  <span className="project-card-category">NFT</span>
                  </div>
                </div>
              </div>
              <div className="second project-card">
                <img
                  className="project-card-profile-img"
                  src={stageraBanner}
                  alt=""
                />
                <div className="project-card-description-bk"></div>
                <div className="project-card-logo">
                  <img src={projectStagera} alt="" />
                </div>
                <div className="project-card-learn-more">
                <a href="https://stagera.io" className="btn-learn-more" target="_blank" rel="noreferrer">
                  <i className="fa fa-globe"></i>
                  </a>
                </div>
                <div className="project-card-description-container">
                  <h2 className="project-card-title">Stagera</h2>
                  <div className="project-card-categories">
                  <span className="project-card-category">Funding</span>
                  <span className="project-card-category">Launchpad</span>
                  </div>
                </div>
              </div>
              <div className="third project-card">
                <img
                  className="project-card-profile-img"
                  src={luinNetworkBanner}
                  alt=""
                />
                <div className="project-card-description-bk"></div>
                <div className="project-card-logo">
                  <img src={projectLuinNetwork} alt="" />
                </div>
                <div className="project-card-learn-more">
                <a href="https://luin.co" className="btn-learn-more" >
                  <i className="fa fa-globe"></i>
                  </a>
                </div>
                <div className="project-card-description-container">
                  <h2 className="project-card-title">Luin Network</h2>
                  <div className="project-card-categories">
                  <span className="project-card-category">Subnet</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Desktop>
        <Tablet>
          <div className="section-project-container-tb">
            <div className="title-container-tb">
              <h2 className="first-title-tb">Projects</h2>
              <span className="second-title-tb">
                We Develop Projects That will Create Added Value
              </span>
              <div className="description-container-tb">
                <p className="description-tb">
                  We work hard and have fun together. As an early believers of
                  web3.0, we would like to change the world by undersigning
                  projects that will create an advanced virtual economy for the
                  benefit of people, powered by blockchain technology.
                </p>
              </div>
            </div>
            <div className="project-assets-container">
            <div className="first project-card">
                <img
                  className="project-card-profile-img"
                  src={ravelinBanner}
                  alt=""
                />
                <div className="project-card-description-bk"></div>
                <div className="project-card-logo">
                  <img className="ravelin-logo-img" src={projectRavelin} alt="" />
                </div>
                <div className="project-card-learn-more">
                <a href="https://ravelin.network" className="btn-learn-more" target="_blank" rel="noreferrer">

                  <i className="fa fa-globe"></i>
                  </a>
                </div>
                <div className="project-card-description-container">
                  <h2 className="project-card-title">Ravelin</h2>
                  <div className="project-card-categories">
                  <span className="project-card-category">Gaming</span>
                  <span className="project-card-category">NFT</span>
                  </div>
                </div>
              </div>
              <div className="second project-card">
                <img
                  className="project-card-profile-img"
                  src={stageraBanner}
                  alt=""
                />
                <div className="project-card-description-bk"></div>
                <div className="project-card-logo">
                  <img src={projectStagera} alt="" />
                </div>
                <div className="project-card-learn-more">
                <a href="https://stagera.io" className="btn-learn-more" target="_blank" rel="noreferrer">
                  <i className="fa fa-globe"></i>
                  </a>
                </div>
                <div className="project-card-description-container">
                  <h2 className="project-card-title">Stagera</h2>
                  <div className="project-card-categories">
                  <span className="project-card-category">Funding</span>
                  <span className="project-card-category">Launchpad</span>
                  </div>
                </div>
              </div>
              <div className="third project-card">
                <img
                  className="project-card-profile-img"
                  src={luinNetworkBanner}
                  alt=""
                />
                <div className="project-card-description-bk"></div>
                <div className="project-card-logo">
                  <img src={projectLuinNetwork} alt="" />
                </div>
                <div className="project-card-learn-more">
                <a href="https://luin.co" className="btn-learn-more" >
                  <i className="fa fa-globe"></i>
                  </a>
                </div>
                <div className="project-card-description-container">
                  <h2 className="project-card-title">Luin Network</h2>
                  <div className="project-card-categories">
                  <span className="project-card-category">Subnet</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Tablet>
        <Mobile>
          <div className="section-project-container-mb">
            <div className="title-container-mb">
              <h2 className="first-title-mb">Projects</h2>
              <p className="second-title-mb">
                We Develop Projects That will Create Added Value
              </p>
              <div className="description-container-mb">
                <p className="description-mb">
                  We work hard and have fun together. As an early believers of
                  web3.0, we would like to change the world by undersigning
                  projects that will create an advanced virtual economy for the
                  benefit of people, powered by blockchain technology.
                </p>
              </div>
            </div>
            <div className="project-assets-container">
            <div className="first project-card-mb">
                <img
                  className="project-card-profile-img"
                  src={ravelinBanner}
                  alt=""
                />
                <div className="project-card-description-bk"></div>
                <div className="project-card-logo">
                  <img className="ravelin-logo-img" src={projectRavelin} alt="" />
                </div>
                <div className="project-card-learn-more">
                  <a href="https://ravelin.network" className="btn-learn-more" target="_blank" rel="noreferrer">
                  <i className="fa fa-globe"></i>
                  </a>
                </div>
                <div className="project-card-description-container">
                  <h2 className="project-card-title">Ravelin</h2>
                  <div className="project-card-categories">
                  <span className="project-card-category">Gaming</span>
                  <span className="project-card-category">NFT</span>
                  </div>
                </div>
              </div>
              <div className="second project-card-mb">
                <img
                  className="project-card-profile-img"
                  src={stageraBanner}
                  alt=""
                />
                <div className="project-card-description-bk"></div>
                <div className="project-card-logo">
                  <img src={projectStagera} alt="" />
                </div>
                <div className="project-card-learn-more">
                  <a href="https://stagera.io" className="btn-learn-more" target="_blank" rel="noreferrer">
                  <i className="fa fa-globe"></i>
                  </a>
                </div>
                <div className="project-card-description-container">
                  <h2 className="project-card-title">Stagera</h2>
                  <div className="project-card-categories">
                  <span className="project-card-category">Funding</span>
                  <span className="project-card-category">Launchpad</span>
                  </div>
                </div>
              </div>
              <div className="third project-card-mb">
                <img
                  className="project-card-profile-img"
                  src={luinNetworkBanner}
                  alt=""
                />
                <div className="project-card-description-bk"></div>
                <div className="project-card-logo">
                  <img src={projectLuinNetwork} alt="" />
                </div>
                <div className="project-card-learn-more">
                  <a href="https://luin.co" className="btn-learn-more" >
                  <i className="fa fa-globe"></i>
                  </a>
                </div>
                <div className="project-card-description-container">
                  <h2 className="project-card-title">Luin Network</h2>
                  <div className="project-card-categories">
                  <span className="project-card-category">Subnet</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Mobile>
      </>
    );
  }
}

export default Projects;
