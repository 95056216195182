// components

import React from "react";

import { useMediaQuery } from "react-responsive";
import { Parallax } from "react-scroll-parallax";

import { HashLink } from 'react-router-hash-link';

import video from "./../../assets/vids/background.mp4";

const Desktop = ({ children }: any) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};
const Tablet = ({ children }: any) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? children : null;
};
const Mobile = ({ children }: any) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

class Zoom extends React.Component<any, any> {
  ref: HTMLElement | null | undefined;

  // @ts-ignore
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.state = {
      classNameArrow: "",
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll = () => {
    if (window.pageYOffset > 0) {
      if (!this.state.classNameArrow) {
        this.setState({ classNameArrow: "hidden" });
      }
    } else {
      if (this.state.classNameArrow) {
        this.setState({ classNameArrow: "" });
      }
    }
  };
  // @ts-ignore
  public render() {
    return (
      <>
        <Desktop>
          <div className="zoom">
            <video autoPlay loop muted playsInline >
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="card-on-zoom">
              <Parallax
                speed={10}
                scale={[1, 0.8]}
                translateY={[0, -200]}
                easing="easeInCubic"
              >
                <h1> Hi, We are Luin</h1>
                <span>
                  <p>
                    We are a team of people who are early adopters of
                    blockchain.
                  </p>
                </span>
              </Parallax>
            </div>
            <HashLink smooth to="#about">
            <div className={`arrow bounce ${this.state.classNameArrow}`}>
              <i className="fa fa-chevron-down fa-2x"></i>
            </div>
            </HashLink>
          </div>
        </Desktop>
        <Tablet>
        <div className="zoom">
            <video autoPlay loop muted>
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="card-on-zoom-tb">
              <Parallax
                speed={10}
                scale={[1, 1.4]}
                translateY={[0, -100]}
                easing="easeInCubic"
              >
                <h1> Hi, We are Luin</h1>
                <span>
                  <p>
                    We are a team of people who are early adopters of
                    blockchain.
                  </p>
                </span>
              </Parallax>
            </div>
            <HashLink smooth to="#about">
            <div className={`arrow bounce ${this.state.classNameArrow}`}>
              <i className="fa fa-chevron-down fa-2x"></i>
            </div>
            </HashLink>
          </div>
        </Tablet>
        <Mobile>
          <div className="zoom">
            <video autoPlay loop muted>
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="card-on-zoom-container-mb">
              <Parallax
                speed={-10}
                scale={[1, 1.4]}
                translateY={[15, -200]}
                easing="easeInCubic"
              >
                <div className="card-on-zoom-mb">
                <span className="deco-title">Hi,</span>
                <span className="deco-title">We</span>
                <span className="deco-title">are</span>
                <span className="deco-title">Luin</span>
                </div>
              </Parallax>
            </div>
            <HashLink smooth to="#about">
            <div className={`arrow bounce ${this.state.classNameArrow}`}>
              <i className="fa fa-chevron-down fa-2x"></i>
            </div>
            </HashLink>
          </div>
        </Mobile>
      </>
    );
  }
}

export default Zoom;
