// components

import React from "react";

import { useMediaQuery } from "react-responsive";

import growingTogether from "./../../assets/img/growing-together.svg";
import buildingTogether from "./../../assets/img/building-together.svg";

const Desktop = ({ children }: any) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};
const Tablet = ({ children }: any) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? children : null;
};
const Mobile = ({ children }: any) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

class About extends React.Component<any, any> {
  // @ts-ignore
  public render() {
    return (
      <>
        <Desktop>
          <div className="section-about-container">
            <div className="title-container">
              <h2 className="first-title">About Us</h2>
              <span className="second-title">
                An innovative, strong and extraordinary team
              </span>
              <div className="description-container">
                <p className="description">
                  We carry centralized projects and values to a decentralized
                  environment. While doing this, we aim to make the project more
                  valuable. We are trying to adapt to the latest developments on
                  the blockchain as quickly as possible.
                </p>
              </div>
              </div>
              <div className="about-content-container">
                <div className="about-cards-container">
                  <div className="about-card">
                    <div className="about-card-img-container">
                      <img src={growingTogether} alt="growing together" />
                    </div>
                    <div className="about-card-title-container">
                      <span>We are growing together</span>
                    </div>
                  </div>

                  <div className="about-card">
                    <div className="about-card-img-container">
                      <img src={buildingTogether} alt="building together" />
                    </div>
                    <div className="about-card-title-container">
                      <span>We are building together</span>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </Desktop>
        <Tablet>
        <div className="section-about-container-tb">
            <div className="title-container-tb">
              <h2 className="first-title-tb">About Us</h2>
              <p className="second-title-tb">
                An innovative, strong and extraordinary team
              </p>
              <div className="description-container-tb">
                <p className="description-tb">
                  We carry centralized projects and values to a decentralized
                  environment. While doing this, we aim to make the project more
                  valuable. We are trying to adapt to the latest developments on
                  the blockchain as quickly as possible.
                </p>
              </div>
              </div>
              <div className="about-content-container-tb">
                <div className="about-cards-container-tb">
                  <div className="about-card-tb">
                    <div className="about-card-img-container-tb">
                      <img src={growingTogether} alt="growing together" />
                    </div>
                    <div className="about-card-title-container-tb">
                      <span>We are growing together</span>
                    </div>
                  </div>
                  <div className="about-card-tb">
                    <div className="about-card-img-container-tb">
                      <img src={buildingTogether} alt="building together" />
                    </div>
                    <div className="about-card-title-container-tb">
                      <span>We are building together</span>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </Tablet>
        <Mobile>
          <div className="section-about-container-mb">
            <div className="title-container-mb">
              <h2 className="first-title-mb">About Us</h2>
              <p className="second-title-mb">
                An innovative, strong and extraordinary team
              </p>
              <div className="description-container-mb">
                <p className="description-mb">
                  We carry centralized projects and values to a decentralized
                  environment. While doing this, we aim to make the project more
                  valuable. We are trying to adapt to the latest developments on
                  the blockchain as quickly as possible.
                </p>
              </div>
              </div>
              <div className="about-content-container-mb">
                <div className="about-cards-container-mb">
                  <div className="about-card-mb">
                    <div className="about-card-img-container-mb">
                      <img src={growingTogether} alt="growing together" />
                    </div>
                    <div className="about-card-title-container-mb">
                      <span>We are growing together</span>
                    </div>
                  </div>
                  <div className="about-card-mb">
                    <div className="about-card-img-container-mb">
                      <img src={buildingTogether} alt="building together" />
                    </div>
                    <div className="about-card-title-container-mb">
                      <span>We are building together</span>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </Mobile>
      </>
    );
  }
}

export default About;
