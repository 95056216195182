/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import logoNegative from "./../../assets/img/logo-negative.png";

import { useMediaQuery } from "react-responsive";

const Desktop = ({ children }: any) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};
const Tablet = ({ children }: any) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? children : null;
};
const Mobile = ({ children }: any) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

// components
class Header extends React.Component<any, any> {
  ref: HTMLElement | null | undefined;

  // @ts-ignore
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.state = {
      navbarOpen: false,
      setNavbarOpen: false,
      classNameTransparentify: "",
      classNameSideNav: "",
      expandedSideNav: false,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    if (window.pageYOffset > 0) {
      if (!this.state.classNameTransparentify) {
        this.setState({ classNameTransparentify: "transparentify" });
      }
    } else {
      if (this.state.classNameTransparentify) {
        this.setState({ classNameTransparentify: "" });
      }
    }
  };

  sideNavClick = () => {
    if (!this.state.expandedSideNav) {
      this.setState({ classNameSideNav: "side-nav-open-mb" });
    } else {
      this.setState({ classNameSideNav: "" });
    }
    this.setState({ expandedSideNav: !this.state.expandedSideNav });
  };

  public render() {
    const {
      /*navbarOpen, /*setNavbarOpen*/
    } = this.state;
    return (
      <>
        <Desktop>
          <nav ref={(r) => (this.ref = r)} className="navbar">
            <div className="navbar-container">
              <div
                className={`navbar-contentify ${this.state.classNameTransparentify}`}
              >
                <Link to="https://luin.co">
                  <img
                    alt="Luin-Team"
                    className="navbar-logo-text"
                    src={logoNegative}
                  />
                </Link>
                <div className="nav-links">
                  <ul className="menu">
                    <li>
                      <a className="menu-item-alt" href="#">
                        Home
                      </a>
                    </li>
                    <li>
                      <a className="menu-item-alt" href="#about">
                        About
                      </a>
                    </li>
                    <li>
                      <a className="menu-item-alt" href="#projects">
                        Projects
                      </a>
                    </li>
                    <li>
                      <a className="menu-item-alt" href="#investments">
                        Investments
                      </a>
                    </li>
                    <li>
                      <a className="menu-item-alt" href="#team">
                        Team
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </Desktop>
        <Tablet>
          <nav ref={(r) => (this.ref = r)} className="navbar-tb">
            <div className="navbar-container-tb">
              <div
                className={`navbar-contentify-tb ${this.state.classNameTransparentify}`}
              >
                <Link to="#">
                  <img
                    alt="Luin-Team"
                    className="navbar-logo-text-tb"
                    src={logoNegative}
                  />
                </Link>
                <div className="nav-links-tb">
                  <ul className="menu-tb">
                    <li>
                      <a className="menu-item-alt" href="#">
                        Home
                      </a>
                    </li>
                    <li>
                      <a className="menu-item-alt" href="#about">
                        About
                      </a>
                    </li>
                    <li>
                      <a className="menu-item-alt" href="#projects">
                        Projects
                      </a>
                    </li>
                    <li>
                      <a className="menu-item-alt" href="#investments">
                        Investments
                      </a>
                    </li>
                    <li>
                      <a className="menu-item-alt" href="#team">
                        Team
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </Tablet>
        <Mobile>
          <nav ref={(r) => (this.ref = r)} className="navbar-mb">
            <div className="navbar-container-mb">
              <div className="navbar-contentify-mb">
                <Link to="#">
                  <img
                    alt="Luin-Team"
                    className="navbar-logo-text-mb"
                    src={logoNegative}
                  />
                </Link>
                <div className="navbar-menu-mb">
                  <a onClick={this.sideNavClick}>
                    <i className="fas fa-bars" />
                  </a>
                </div>
              </div>
            </div>
          </nav>
          <nav className={`side-nav-mb ${this.state.classNameSideNav}`}>
            <div className="nav-links-mb">
              <ul className="menu-mb">
                <li>
                  <a onClick={this.sideNavClick} href="#">
                    Home
                  </a>
                </li>
                <li>
                  <a onClick={this.sideNavClick} href="#about">
                    About
                  </a>
                </li>
                <li>
                  <a onClick={this.sideNavClick} href="#projects">
                    Projects
                  </a>
                </li>
                <li>
                  <a onClick={this.sideNavClick} href="#investments">
                    Investments
                  </a>
                </li>
                <li>
                  <a onClick={this.sideNavClick} href="#team">
                    Team
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </Mobile>
      </>
    );
  }
}

export default Header;
