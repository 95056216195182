import React from "react";

import { useMediaQuery } from "react-responsive";

const Desktop = ({ children }: any) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};
const Tablet = ({ children }: any) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? children : null;
};
const Mobile = ({ children }: any) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

class Footer extends React.Component<any, any> {
  // @ts-ignore
  public render() {
    return (
      <>
        <Desktop>
          <footer id="footer" className="footer-relative">
            <div className="area-container">
              <div className="text-content">
                <h3> Follow Us </h3>
                <span className="dont-miss">
                  Not to miss the Luin Team news{" "}
                </span>
                <span className="mail"> info@luin.co </span>
              </div>
            </div>
            <div className="footer-social">
              <div className="color"></div>
              <div className="color"></div>
              <div className="color"></div>
              <ul>
                <li>
                  <a
                    className="menu-item-alt"
                    href="https://twitter.com/LuinTeam"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-twitter" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a
                    className="menu-item-alt"
                    href="https://t.me/LuinTeam"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-telegram-plane" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a
                    className="menu-item-alt"
                    href="https://www.instagram.com/luinteam/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-instagram" aria-hidden="true"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer-copyright">
              <div className="footer-copyright-text">
                Luin Team © {new Date().getFullYear()} All rights reserved
              </div>
            </div>
          </footer>
        </Desktop>
        <Tablet>
        <footer id="footer" className="footer-relative-tb">
            <div className="area-container-tb">
              <div className="text-content-tb">
                <h3> Follow Us </h3>
                <span className="dont-miss-tb">
                  Not to miss the Luin Team news{" "}
                </span>
                <span className="mail-tb"> info@luin.co </span>
              </div>
            </div>
            <div className="footer-social-tb">
              <div className="color"></div>
              <div className="color"></div>
              <div className="color"></div>
              <ul>
                <li>
                  <a
                    className="menu-item-alt"
                    href="https://twitter.com/LuinTeam"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-twitter" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a
                    className="menu-item-alt"
                    href="https://t.me/LuinTeam"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-telegram-plane" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a
                    className="menu-item-alt"
                    href="https://www.instagram.com/luinteam/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-instagram" aria-hidden="true"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer-copyright-tb">
              <div className="footer-copyright-text-tb">
                Luin Team © {new Date().getFullYear()} All rights reserved
              </div>
            </div>
          </footer>
        </Tablet>
        <Mobile>
        <footer id="footer" className="footer-relative-mb">
            <div className="area-container-mb">
              <div className="text-content-mb">
                <h3> Follow Us </h3>
                <span className="dont-miss-mb">
                  Not to miss the Luin Team news{" "}
                </span>
                <span className="mail-mb"> info@luin.co </span>
              </div>
            </div>
            <div className="footer-social-mb">
              <div className="color"></div>
              <div className="color"></div>
              <div className="color"></div>
              <ul>
                <li>
                  <a
                    className="menu-item-alt"
                    href="https://twitter.com/LuinTeam"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-twitter" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a
                    className="menu-item-alt"
                    href="https://t.me/LuinTeam"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-telegram-plane" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a
                    className="menu-item-alt"
                    href="https://www.instagram.com/luinteam/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-instagram" aria-hidden="true"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer-copyright-mb">
              <div className="footer-copyright-text-mb">
                Luin Team © {new Date().getFullYear()} All rights reserved
              </div>
            </div>
          </footer>
        </Mobile>
      </>
    );
  }
}

export default Footer;
